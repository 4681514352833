<template>
  <div id="home">
    <div class="header">
      <div class="header-top">
        <div class="header-top-left" @click="changeShow"></div>
        <div class="header-top-right">客服热线：400 110 1500</div>
      </div>
      <div class="jrmc">
        <img src="./img/jrmc.png" alt="jrmc" class="jrmc-img" />
      </div>
      <div class="header-text">
        <div class="txt1">
          超多车型<span style="margin-right: 13px"></span>放心透明<span
            style="margin-right: 13px"
          ></span
          >购买方便<span style="margin-right: 13px"></span>省钱省心
        </div>
      </div>
      <div class="er-code">
        <div class="er-code-left">
          <div class="er_code-left-min">

            <div class="er-code-left-bto">
              <img
                v-show="iosShow"
                src="./img/andr-ios.png"
                alt="code"
                style="width: 80px; height: 80px"
              />
              <img
                v-show="androidShow"
                src="./img/andr-android.png"
                alt="code"
                style="width: 80px; height: 80px; display: inline-block"
                @click.stop="downloadApp"
              />
            </div>

            <div class="er-code-left-top">
              <div class="wx-img" v-show="androidShow">
                <img
                  src="./img/andeoidimg.png"
                  alt="wx"
                  style="width: 16px; height: 14px"
                />
                <p style="width: 2px"></p>
                <div class="wx-text">Android</div>
              </div>
              <!-- <div ()">下载</div> -->
              <div class="wx-img" v-show="iosShow">
                <img
                  src="./img/IOS-img.png"
                  alt="wx"
                  style="width: 16px; height: 14px"
                />
                <p style="width: 2px"></p>
                <div class="wx-text">IOS</div>
              </div>
            </div>
            
          </div>
          <div class="er_code-left-min">
            <div class="er-code-left-bto">
              <img
                src="./img/wx-img.png"
                alt="code"
                style="width: 80px; height: 80px"
              />
            </div>
            <div class="er-code-left-top">
              <div class="wx-img">
                <img
                  src="./img/andr-wx.png"
                  alt="wx"
                  style="width: 16px; height: 14px"
                />
                <p style="width: 2px"></p>
                <div class="wx-text">小程序入口</div>
              </div>
            </div>
          </div>

          <div class="er-code-left-bto-text">
            请用微信扫码进入<br />
            或微信浏览器中请长按识别
          </div>
        </div>
        <div class="er-code-right">
          <div class="iphone-img">
            <div class="iphone">
              <img
                src="./img/iPhone.png"
                alt="iphone"
                style="width: 138px; height: 277px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="middle-top">
        <div class="top-img"></div>
      </div>
      <div class="middle-text-wrap">
        <div style="width: 240px; height: 34px">
          <div class="middle-text">
            不管是神车还是大众品牌，超多车型任你挑选
          </div>
          <div class="middle-text">价格透明、服务透明、一切都放心</div>
        </div>
      </div>
      <!--  轮播1    -->
      <div class="swipe1-icon">
        <img
          src="./img/logo-wall.png"
          :style="{ height: logoWallHeight + 'px' }"
        />
      </div>
      <!--  轮播2    -->
      <div class="swiperView2">
        <div class="swiper-container swiper2">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide swiperSlide2"
              v-for="(item, index) in swiper2Img"
              :key="index"
            >
              <img :src="item.url" class="imgView" />
              <div class="priceImgView">
                <img
                  :src="item.url2"
                  mode="aspectFit"
                  class="priceImg"
                  width="50"
                  height="50"
                />
              </div>
            </div>
          </div>
          <div class="swiper-button-prev buttonPrev2"></div>
          <div class="swiper-button-next buttonNext2"></div>
          <div class="swiper-pagination2 pagination2"></div>
        </div>
        <!--  车对应的文字    -->
        <div class="swiper2-text">
          <ul class="swiper2-text-box">
            <li>• {{ carDescribe.one }}</li>
            <li>• {{ carDescribe.two }}</li>
          </ul>
        </div>
      </div>

      <!--   超多车型，放心透明   -->
      <div class="cdcx">
        <img
          src="./img/gmfb.png"
          alt="cdcx"
          style="width: 170px; height: 41px"
        />
      </div>
      <!--   说明文字   -->
      <div class="middle-text-wrap">
        <div style="width: 280px; height: 34px">
          <div class="middle-text">
            不管是神车还是大众品牌，超多车型任你挑选
          </div>
          <div class="middle-text">价格透明、服务透明、一切都放心</div>
        </div>
      </div>
      <!--  流程    -->
      <div class="process">
        <div style="display: flex; width: 330px">
          <div class="process-li">
            <div class="process-img">
              <img src="./img/liucheng1.png" alt="" class="process-img" />
            </div>
            <span class="process-text">在线选车</span>
          </div>
          <div class="process-li2">
            <div class="process-line"></div>
          </div>
          <div class="process-li">
            <div class="process-img">
              <img src="./img/liucheng2.png" alt="" class="process-img" />
            </div>
            <span class="process-text">我的试驾</span>
          </div>
          <div class="process-li2">
            <div class="process-line"></div>
          </div>
          <div class="process-li">
            <div class="process-img">
              <img src="./img/liucheng3.png" alt="" class="process-img" />
            </div>
            <span class="process-text">门店选购</span>
          </div>
          <div class="process-li2">
            <div class="process-line"></div>
          </div>
          <div class="process-li">
            <div class="process-img">
              <img src="./img/liucheng4.png" alt="" class="process-img" />
            </div>
            <span class="process-text">线上支付</span>
          </div>
          <div class="process-li2">
            <div class="process-line"></div>
          </div>
          <div class="process-li">
            <div class="process-img">
              <img src="./img/liucheng5.png" alt="" class="process-img" />
            </div>
            <span class="process-text">上牌验车</span>
          </div>
          <div class="process-li2">
            <div class="process-line"></div>
          </div>
          <div class="process-li">
            <div class="process-img">
              <img src="./img/liucheng6.png" alt="" class="process-img" />
            </div>
            <span class="process-text" style="margin-left: -23px"
              >新车开回家</span
            >
          </div>
        </div>
      </div>

      <!--   底部轮播   -->
      <div class="swiperView3">
        <div class="swiper-container swiper3">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide swiperSlide3"
              v-for="(item, index) in swiper3Img"
              :key="index"
            >
              <img :src="item.url" class="imgView" />
              <div class="layer-mask"></div>
            </div>
          </div>
          <div class="swiper-button-prev buttonPrev3"></div>
          <div class="swiper-button-next buttonNext3"></div>
        </div>
      </div>

      <!--  底部说明    -->
      <div class="footer">
        <div style="width: 112px; height: 34px">
          <div class="footer-text">
            今日买车.贾维斯汽车有限公司<br />
            Cenco Buy Group Co.,Ltd<br />
            <!-- 沪公网安备 3101212121334123号<br> -->
            沪ICP备2021026346号-1<br />
            2020-2021 贾维斯汽车有限公司版权所有
          </div>
        </div>
      </div>
    </div>

    <!--  遮罩层  -->
    <overLay v-if="isOverShow" @handleOverShow="updateShow"></overLay>

    <!--    <van-popup-->
    <!--        v-model="overShow"-->
    <!--        position="left"-->
    <!--        closeable-->
    <!--        close-icon="close"-->
    <!--        :style="{ height: '100%',width:'100%',backgroundColor:'#35234B' }"-->
    <!--    >-->
    <!--      <div class="overIcon"></div>-->
    <!--      <div class="over-txt"-->
    <!--           :key="index"-->
    <!--           v-for="(item,index) in textArr"-->
    <!--           :class="{over_txt_active:isIndex === index}"-->
    <!--           @click="overChange(index)"-->
    <!--      >{{ item.txt }}</div>-->
    <!--      <div class="overlay-footer">-->
    <!--        <img src="./img/overlay-footer.png" alt="overlay-footer" style="width: 160px;height: 51px">-->
    <!--      </div>-->
    <!--    </van-popup>-->
  </div>
</template>

<script>
import "./js/swiper.min.js";
import $ from "jquery";
import { Swipe, SwipeItem, Popup } from "vant";
import swiper from "../../components/test/test2";
import overLay from "../../components/overLay/overLay";

export default {
  name: "home",
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Popup.name]: Popup,
    swiper,
    overLay,
  },
  data() {
    return {
      swiper2Img: [
        {
          url: require("./img/car001.png"),
          url2: require("./img/28800yuan.png"),
          index: 1,
        },
        {
          url: require("./img/car002.png"),
          url2: require("./img/57800yuan.png"),
          index: 2,
        },
        {
          url: require("./img/car003.png"),
          url2: require("./img/298000yuan.png"),
          index: 3,
        },
        {
          url: require("./img/car004.png"),
          url2: require("./img/132800yuan.png"),
          index: 4,
        },
        {
          url: require("./img/car005.png"),
          url2: require("./img/252800yuan.png"),
          index: 5,
        },
        {
          url: require("./img/car006.png"),
          url2: require("./img/176800yuan.png"),
          index: 6,
        },
      ],
      swiper3Img: [
        { url: require("./img/baner-one.jpg"), index: 1 },
        { url: require("./img/baner-two.jpg"), index: 2 },
        { url: require("./img/baner-three.jpg"), index: 3 },
        { url: require("./img/baner-five.jpg"), index: 4 },
        { url: require("./img/baner-six.jpg"), index: 5 },
      ],
      // overShow:false,//遮罩层默认不显示
      // textArr:[
      //   {txt:"首页",index:1},
      //   {txt:"关于我们",index:2}
      // ],
      // isIndex:0,//默认首页字体
      carDescribe: {
        one: "热销车型",
        two: "人民的代步车-五菱宏光MINIEV",
      }, //车描述
      carPrice: "28800", //车价格
      priceShow: false,

      //蒙层组件是否显示
      isOverShow: false,
      logoWallHeight: 0,
      androidShow: false,
      iosShow: false,
      JumpInfo: undefined,
    };
  },
  created() {
    this.model();
  },
  mounted() {
    this.swiper3();
    this.swiper2();
    this.logoWallHeight = Number.parseFloat(window.screen.width / 4);
  },
  methods: {
    model() {
      var u = navigator.userAgent;
      if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
        //安卓手机
        this.androidShow = true;
        // this.iosShow = false;
      } else if (u.indexOf("iPhone") > -1) {
        //苹果手机
        //  alert("苹果手机");
        this.iosShow = true;
        // this.androidShow = false;
      } else {
        var isWeixin = ua.indexOf("micromessenger") != -1;
        console.log(isWeixin);
      }
    },
    //子组件传值遮罩层关闭
    updateShow() {
      this.isOverShow = false;
    },
    //点击图标显示遮罩层
    changeShow() {
      // this.overShow = true
      this.isOverShow = true;
    },
    /* 点击下载按钮 */

    downloadApp() {
      this.JumpInfo = {
        IOSURL: `https://apps.apple.com/cn/app/id1577345106`,
        AndroidURL: `https://www.cencobuy.cn/app/android/cencobuy.apk`,
      };
      this.$ownLib.downApp(this.JumpInfo.IOSURL, this.JumpInfo.AndroidURL);
      console.log(this.$ownLib.downApp);
    },
    //点击菜单字体事件
    // overChange(index){
    //   this.isIndex = index
    //   console.log(this.isIndex);
    //   this.overShow = false
    //   if( index == 0 && this.$route.path == '/home'){
    //     return
    //   }else if(index ==  0){
    //     this.$router.push({path:'/home'})
    //   }
    //   if( index == 1 && this.$route.path == '/about'){
    //     return
    //   }else if(index ==  1){
    //     this.$router.push({path:'/about'})
    //   }
    // },
    //轮播2事件
    onSwiper2(e) {
      if (e == 0) {
        this.carDescribe.one = "热销车型";
        this.carDescribe.two = "人民的代步车-五菱宏光MINIEV";
      } else if (e == 1) {
        this.carDescribe.one = "热销车型";
        this.carDescribe.two = "宽体商务车-五菱宏光PLUS";
      } else if (e == 2) {
        this.carDescribe.one = "大·不至于大";
        this.carDescribe.two = "强者逐新-全新途昂";
      } else if (e == 3) {
        this.carDescribe.one = "3A品味 格调家轿";
        this.carDescribe.two = "智能互联-福特蒙迪欧";
      } else if (e == 4) {
        this.carDescribe.one = "征服更高处";
        this.carDescribe.two = "十年强者7座空间-汉兰达";
      } else if (e == 5) {
        this.carDescribe.one = "尽享酣畅驾乘快感";
        this.carDescribe.two = "科技座舱-丰田凯美瑞";
      }
    },

    swiper2() {
      let that = this;
      var swiper2 = new Swiper(".swiper2", {
        autoplay: false,
        speed: 500,
        autoplayDisableOnInteraction: false,
        loop: true,
        loopAdditionalSlides: 1,
        loopedSlides: 6,
        centeredSlides: true,
        spaceBetween: -140,
        slidesPerView: 2,
        // slidesPerView: auto,
        autoHeight: true,
        grabCursor: true,

        prevButton: ".buttonPrev2",
        nextButton: ".buttonNext2",
        pagination: ".swiper-pagination2",
        clickable: true,
        paginationClickable: true,
        onInit: function (swiper2) {
          swiper2.slides[2].className =
            "swiperSlide2 swiper-slide swiper-slide-active";
          // that.setAnimation(swiper2.realIndex,true)//默认显示中间价格
        },
        onClick(swiper) {
          //点击事件
          // let priceImgView = $('.swiper-slide[data-swiper-slide-index=' + swiper.realIndex + '] .priceImgView');
          // that.setAnimation(swiper.realIndex,true)//点击显示中间价格
          // that.onSwiper2(swiper.realIndex)//下方车型描述事件
        },
        //滑动到下一个slide触发事件
        onSlideChangeEnd(swiper) {
          console.log(swiper);
          that.onSwiper2(swiper.realIndex);
        },
        // onSliderMove(swiper, event){
        //   that.setAnimation(swiper.realIndex,true)//点击显示中间价格
        //   that.onSwiper2(swiper.realIndex)//下方车型描述事件
        // },
        breakpoints: {
          700: {
            slidesPerView: 1,
          },
        },
      });
    },

    //价格是否显示函数
    // setAnimation(realIndex, falg){
    //   let priceImgView = $('.swiper-slide[data-swiper-slide-index=' + realIndex + '] .priceImgView');
    //   $('.swiper-slide .priceImgView').hide();
    //   this.setInAnimation(priceImgView);
    // },
    // setInAnimation(el) {
    //   el.show();
    // },

    //底部图片轮播
    swiper3() {
      var mySwiper = new Swiper(".swiper3", {
        loop: true,
        // 如果需要分页器
        pagination: ".swiper-pagination",
        // 如果需要前进后退按钮
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        // 如果需要滚动条
        autoplay: false,
        speed: 500,
        autoplayDisableOnInteraction: false,
        centeredSlides: true,
        spaceBetween: -90,
        slidesPerView: 2,
        paginationClickable: true,
        onInit: function (swiper3) {
          swiper3.slides[2].className =
            "swiperSlide3 swiper-slide swiper-slide-active";
        },
        breakpoints: {
          700: {
            slidesPerView: 1,
          },
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
@import "./home.less";
@import "../../assets/style/overLay.less";
@import "./css/swiper.min.css";
@import "./test2.css"; //轮播3样式
@import "./css/swiper222.css"; //轮播2样式

/deep/.el-carousel__indicators--outside {
  margin-top: 70px !important;
}
.el-carousel__item:nth-child(2n) {
}

.el-carousel__item:nth-child(2n + 1) {
}

/deep/.van-swipe-item {
  width: 89px !important;
}

/deep/.van-icon-close::before {
  font-size: 35px;
}
</style>
