
<template>
  <div class="swiper-container swiper3">

  </div>
</template>

<script>
import './js/swiper.min.js'


export default {
  name: 'test2',
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
    this.swiper()
  },
  methods:{
    swiper(){
      var mySwiper = new Swiper ('.swiper-container', {
        loop: true,

        // 如果需要分页器
        pagination: '.swiper-pagination',

        // 如果需要前进后退按钮
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',

        // 如果需要滚动条

        autoplay: false,
        speed: 500,
        autoplayDisableOnInteraction: false,
        centeredSlides: true,
        spaceBetween: -30,
        slidesPerView: 2,
        paginationClickable: true,
        onInit: function(swiper3) {
          swiper3.slides[2].className = "swiperSlide3 swiper-slide swiper-slide-active";
        },
        breakpoints: {
          700: {
            slidesPerView: 1,
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./test2.css";

</style>